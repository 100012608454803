/* AppNavbar.css */

/* Set the background color of the navbar to white */
.navbar {
    /* background-color: white !important;  */
    /* !important ensures override of Bootstrap's default styles */
    background-color: rgb(254, 254, 254) !important; 
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
}

/* Align navbar items to the right */
.navbar-collapse {
    justify-content: flex-end;
}

/* Remove the border from the collapsed burger button */
.navbar-toggler {
    border: none;
}

/* Remove the focus border/outline from the burger button (optional) */
.navbar-toggler:focus {
    outline: none;
    box-shadow: none;
}

/* Additional styles to ensure navbar items are aligned correctly */
.navbar-nav {
    align-items: center;
}

/* Style adjustments for the navbar links, if needed */
.nav-link {
    margin-right: 1rem; /* Adjust space between links */
}

.container {
    /* padding-top: 30px !important;
    padding-bottom: 30px !important; */
    padding: 30px 50px !important;
}

