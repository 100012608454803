/* General Styles */
html, body {
  height: 100%; 
  margin: 0; 
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* color: #71797E; */
  background-color: rgb(254, 254, 254);
}

/* Layout with Flexbox */
body {
  display: flex; 
  flex-direction: column; 
  min-height: 100vh; /* Use viewport height to ensure full height */
}

.App {
   /* Allows the app container to fill the available space */
  /* flex-grow: 1; */
  text-align: center;
  display: flex; 
  flex-direction: column; 
  min-height: 100vh; /* Ensures it takes at least the full viewport height */
}

/* Footer Styling */
.footer {
  /* position: absolute;  */
  /* position: relative;  */
  /* position: fixed; */
  /* padding-top: 30px;
  padding-bottom: 30px; */
  /* bottom: 0; 
  width: 100%;
  height: 2.5rem;  */
  margin-top: auto; 
  color: gray;
}

footer p {
  font-size: 0.9rem;
}

/* Navigation, Header, and Other Components */
.App-header {
  /* Adjusted the min-height as per your layout needs */
  min-height: calc(100vh - 60px); /* Example: Adjust 60px to account for other elements like header/nav */
  background-color: #282c34;
  color: white;
  font-size: calc(10px + 2vmin);
  display: flex;
  align-items: center;
  justify-content: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

/* Container and Content Styling */
.container {
  padding: 50px 0; /* Reduced padding for better spacing */
  flex: 1;
}

.img-container {
  margin-bottom: 30px; 
}

.avatar {
  width: 15%;  
  border-radius: 50%;
}

.avatar-tag {
  font-size: 1rem; 
}

.intro-container {
  display: grid;
  place-items: center;
  width: 60%;              /* Set container width to 50% of its parent */
  margin: 0 auto;          /* Additional centering for horizontal */
  margin-top: 30px; 
}

p {
  font-size: 1.25rem; 
  padding-bottom: 10px; 
}

.link-btn {
  text-decoration: none;
  color: grey;
}

a:hover {
  font-weight: bold;
}

/* ContactForm.css */
.contact-form {
  /* display: grid; */
  /* place-items: center; */
  display: flex; 
  flex-direction: column;
  align-items: center;    
  /* Additional centering for horizontal */
  margin: 0 auto;   
  padding: 20px 0; 
  width: 100%; /* Make the form width responsive */
  max-width: 500px; /* Set the maximum width of the form */
}

.contact-form .form-group {
  width: 100%; /* Ensure form groups take full width of the form */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.contact-form .form-label {
  text-align: left;
  margin-bottom: 0.25rem; 
}

.contact-form .borderless-input, .contact-form textarea {
  border: none;
  border-bottom: 1px solid #ced4da; /* Bottom border only */
  border-radius: 0; /* Removes the default border-radius */
  box-shadow: none; /* Removes box shadow if any */
  width: 100%; /* Make input fields responsive */
  padding-left: 0; /* Align text in input with label */
}

.contact-form .borderless-input:focus {
  border-color: grey;
  outline: 0; /* Removes the default focus outline */
  box-shadow: none; /* Removes box shadow on focus */
}

/* Align label to the top */
.align-top {
  vertical-align: top;
}

.contact-btn {
  margin-top: 10px; 
}

.btn:hover {
  font-weight: bold; 
}